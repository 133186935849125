import { initializeApp, getApp  } from 'firebase/app'

const FirebaseContext = React.createContext(null)

export function useFirebaseApp() {
  return React.useContext(FirebaseContext)
}

export function FirebaseAppProvider({ name, config, children }) {
  const [firebaseApp, setFirebaseApp] = React.useState(null)
  React.useEffect(
    () => {
      let firebaseApp

      try {
        firebaseApp = getApp(name)
      } catch (err) {
        firebaseApp = initializeApp(config, name)
      }

      setFirebaseApp(firebaseApp)
    },
    [name, config]
  )

  return <FirebaseContext.Provider value={firebaseApp}>{children}</FirebaseContext.Provider>
}
