import { useForm, useFormField } from '@kaliber/forms'
import { required, email } from '/machinery/customValidation'
import { useFirebaseApp, FirebaseAppProvider } from '/machinery/firebase'
import { getDatabase, push, ref, serverTimestamp } from 'firebase/database'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { QueryClient, QueryClientProvider, useMutation } from '@tanstack/react-query'
import { VisuallyHidden } from '/components/a11y/VisuallyHidden'
import { ContainerLg } from '/components/buildingBlocks/Container'
import { HeadingPrimaryXxl } from '/components/buildingBlocks/Heading'
import { TextPrimaryMd } from '/components/buildingBlocks/Text'
import { Icon } from '/components/buildingBlocks/Icon'
import { useClientConfig } from '/machinery/ClientConfig'

import styles from './EmailSubscribeForm.css'
import arrows from '/images/icons/arrows.raw.svg'

const queryClient = new QueryClient()

const validationErrors = {
  required: 'This field is required',
  email: 'Please provide a valid email address',
}

export function EmailSubscribeForm({ layoutClassName }) {
  return (
    <EmailSubscribeFormContext>
      <EmailSubscribeFormImpl {...{ layoutClassName }} />
    </EmailSubscribeFormContext>
  )
}

function EmailSubscribeFormImpl({ layoutClassName }) {
  const { form: { fields }, submit } = useForm({
    initialValues: { email: '' },
    fields: { email: [required, email] },
    onSubmit,
  })
  const btnRef = React.useRef(null)

  const firebase = useFirebaseApp()

  const { mutate, isSuccess: isSubmitted } = useMutation({
    mutationFn: submitFormFields,
  })

  return (
    <ContainerLg {...{ layoutClassName }}>
      <div className={styles.componentImpl}>
        <div className={styles.inner}>
          <HeadingPrimaryXxl heading='Stay tuned' h={3} />
          <TextPrimaryMd layoutClassName={styles.textLayout}>
            We are only at the start of our partnership. Stay tuned for exciting things to come.
          </TextPrimaryMd>
          <form className={styles.form} noValidate onSubmit={submit}>
            <FormFieldEmail
              label="Email"
              placeholder='Email@mail.com'
              field={fields.email}
              layoutClassName={styles.fieldLayout}
              {...{ isSubmitted }}
            />
            {!isSubmitted && <SubmitButton layoutClassName={styles.submitButtonLayout} {...{ btnRef }} />}
          </form>
        </div>
      </div>
    </ContainerLg>
  )

  async function onSubmit(data) {
    btnRef.current.focus()
    if (data?.invalid) return
    mutate(data)
  }

  async function submitFormFields(data) {
    const { user: { uid } } = await signInAnonymously(getAuth(firebase))

    await push(ref(getDatabase(firebase), 'services/email-subscription-service/queue'), {
      formSubmitDate: serverTimestamp(),
      email: data.value.email,
      uid,
    })
  }
}

function FormFieldEmail({ placeholder, field, isSubmitted, label, layoutClassName = undefined }) {
  const { name, state, eventHandlers } = useFormField(field)
  const { value = '', error, showError } = state

  return (
    <FormFieldBase {...{ label, name, error, showError, layoutClassName }}>
      <input
        id={name}
        type='email'
        required
        aria-label='email field'
        disabled={isSubmitted}
        className={styles._rootformFieldText}
        {...{ name, value, placeholder }}
        {...eventHandlers}
      />
      <div className={styles._rootFieldMessages}>
        {showError && <div className={styles.fieldValidation}>{validationErrors[error.id]}</div>}
        {isSubmitted && <div className={styles.fieldValidation}>You have successfully subscribed</div>}
      </div>
    </FormFieldBase>
  )
}

function FormFieldBase({ children, label, name, layoutClassName }) {
  return (
    <div className={layoutClassName}>
      <VisuallyHidden>
        <label htmlFor={name}>{label}</label>
      </VisuallyHidden>
      {children}
    </div>
  )
}

function SubmitButton({ btnRef, layoutClassName }) {
  return (
    <button
      aria-label="Submit to subscibe to our newsletter"
      type="submit"
      className={cx(styles.componentSubmitButton, layoutClassName)}
      ref={btnRef}
    >
      <div className={styles.icon}><Icon icon={arrows} /></div>
      <VisuallyHidden>subscribe</VisuallyHidden>
    </button>
  )
}

function EmailSubscribeFormContext({ children }) {
  const config = useClientConfig()

  return (
    <FirebaseAppProvider config={config.firebase} name={config.firebase.appName}>
      <QueryClientProvider client={queryClient}>
        {children}
      </QueryClientProvider>
    </FirebaseAppProvider>

  )
}
